@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* This won't be included in your compiled CSS unless you actually use it */
  .dots {
    background-color: #f8f8ff;
    opacity: 1;
    background-image: radial-gradient(
      #d7d7d7 0.8500000000000001px,
      #f8f8ff 0.8500000000000001px
    );
    background-size: 17px 17px;
  }

  .dotsDark {
    background-color: #1f1f1f;
    opacity: 1;
    background-image: radial-gradient(
      #1f1e1e 0.8500000000000001px,
      #030303 0.8500000000000001px
    );
    background-size: 17px 17px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bgFade {
  animation: createBox 0.25s;
  z-index: -1000;
}

@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
